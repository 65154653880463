.signup__container {
  background-color: #e2ffee;
  text-align: center;
  position: relative;
}

.signup-header__container {
  position: relative;
}
.signup__image-1 {
  width: 68px;
  height: 68px;
  position: absolute;
  top: -54%;
  left: -4%;
  margin: 0;
}
.signup__image-2 {
  width: 89px;
  height: 89px;
  position: absolute;
  top: -108%;
  right: 3%;
  margin: 0;
}
.signup__image-3 {
  width: 62px;
  height: 62px;
  position: absolute;
  z-index: 1;
  top: -20%;
  left: 44%;
}

.signup-header__subtitle {
  font-family: NewSosis;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 1px #000000;
}
.signup-header__title {
  font-family: NewSosis;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 1px #000000;
}

.signup-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}

input {
  outline: none;
  border: transparent;
  text-align: center;
  font-family: NewSosis;
  font-size: 20px;
  color: #0edbe3;
  background: #e2ffee;
  text-shadow: 2px 3px 0px #161716;
  margin: 1rem 0px 0.5rem;
  -webkit-text-fill-color: #0edbe3;
  -webkit-text-stroke: 1px #000000;
  caret-color: black;
}
input[type="submit"] {
  font-family: GilroyBold;
  padding: 16px 94px;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 28px;
}
input::placeholder {
  color: transparent;
  outline: #161716;
  text-shadow: 0px 0px 0px;
  color: #16171699;
  -webkit-text-fill-color: #e2ffee;
  -webkit-text-stroke: 1.5px #16171699;
  text-align: center;
}

.name-input,
.phone-input {
  transform: rotate(-2deg);
}
.email-input,
.instaid-input {
  transform: rotate(2deg);
}

.signup-form__name-wrapper,
.signup-form__phone-wrapper,
.signup-form__email-wrapper {
  position: relative;
}
.required {
  font-size: 25px;
  position: absolute;
  text-align: center;
  -webkit-text-fill-color: red;
  -webkit-text-stroke: 0.2px #000000;
}
.signup-form__name-wrapper .required {
  right: 38%;
}
.signup-form__email-wrapper .required {
  right: 34%;
}
.signup-form__phone-wrapper .required {
  right: 36%;
}
.error-text-wrapper {
  transform: rotate(2deg);
}
.error-text {
  color: red;
  background-color: white;
  border: 1px solid black;
  border-radius: 16px;
  text-align: center;
  margin: 0px;
  padding: 0px 16px;
}
.display-none {
  display: none;
}
.display {
  display: block;
  font-family: NewSosis;
}
.otp-button__disabled {
  color: gray;
  background-color: rgba(240, 240, 240, 0.5);
  border: 2px solid gray;
  box-shadow: 2px 3px 0px 0px gray;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: gray;
  -webkit-text-stroke: gray;
  font-weight: bold;
}

.otp-button__enabled {
  color: #000000;
  background: linear-gradient(0deg, #ff68e7, #ff68e7);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;
  cursor: pointer;
}

@media (min-width: 1025px) {
  .signup__container {
    display: block;
  }

  .signup-header__container {
    display: block;
  }
  .signup-header__subtitle {
    font-size: 36px;
  }
  .signup-header__title {
    font-size: 50px;
  }

  .signup__image-1 {
    width: 128px;
    height: 128px;
    top: 4rem;
    left: 8%;
  }
  .signup__image-2 {
    width: 137px;
    height: 137px;
    top: 10rem;
    right: 8%;
  }
  .signup__image-3 {
    width: 173px;
    height: 173px;
    position: absolute;
    z-index: 1;
    top: -62%;
    left: 44%;
  }

  .signup-form__name-wrapper .required {
    right: 38%;
  }
  .signup-form__email-wrapper .required {
    right: 34%;
  }
  .signup-form__phone-wrapper .required {
    right: 38%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup__image-1 {
    width: 80px;
    height: 80px;
    top: 4rem;
    left: 8%;
  }
  .signup__image-2 {
    width: 90px;
    height: 90px;
    top: 10rem;
    right: 8%;
  }
  .signup__image-3 {
    width: 100px;
    height: 100px;
    position: absolute;
  z-index: 1;
    top: -35%;
    left: 44%;
  }
}
