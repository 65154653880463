.header__container {
  display: flex;
  justify-content: space-between;
  padding: 5%;
}

.header__logo {
  height: 56px;
  position: absolute;
  left: 1rem;
  z-index: 1;
}

.header__menu-icon {
  width: 48px;
  height: 48px;
}

.header__list {
  display: flex;
  flex-direction: column;
}

.header__list-item {
  font-family: NewSosis;
  text-decoration: none;
  font-size: 20px;
  margin: 16px;
  color: #16171699;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1.5px #16171699;
  background: none;
  border: none;
}

.header__list-item:active {
  color: #0edbe3;
  text-shadow: 2px 3px 0px #161716;
  margin: 1rem;
  -webkit-text-fill-color: #0edbe3;
  -webkit-text-stroke: 1px #000000;
}

.header__list-item:hover {
  background: white;
}

.close-icon {
  font-size: 32px;
  color: gray;
}

.menu-card-icon {
  margin-left: auto;
  text-align: end;
}

.menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.menu-dialog-container {
  text-align: center;
  background-color: white;
  padding: 20px 20px 36px;
  border-radius: 32px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin: 20px 16px;
  transform: rotate(-3deg);
}

#menu-item-0 {
  transform: rotate(-2deg);
}
#menu-item-1 {
  transform: rotate(1deg);
}
#menu-item-2 {
  transform: rotate(-2deg);
}
#menu-item-3 {
  transform: rotate(4deg);
}
#menu-item-4 {
  transform: rotate(-4deg);
}

.web-element {
  display: none;
}

.mobile-element {
  display: block;
}

@media (min-width: 1025px) {
  .web-element {
    display: block;
  }

  .mobile-element {
    display: none;
  }
  .header__container {
    padding: 2% 5%;
  }

  .header__logo {
    height: 83px;
    left: 4rem;
  }

  .header__list {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }

  .header__list-item {
    font-family: NewSosis;
    text-decoration: none;
    font-size: 16px;
    margin: 16px;
    color: #eff4ef99;
    text-shadow: 1px 1.5px 0px #161716;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: 1.2px #16171699;
  }
  .active {
    background-image: url("../../assets/images/shade.png");
    background-size: cover;
  }
  .header__menu-button {
    display: none;
  }

  .header__list {
    display: flex;
    flex-direction: row;
  }

  #menu-item-0,
  #menu-item-1,
  #menu-item-2,
  #menu-item-3,
  #menu-item-4 {
    transform: rotate(0deg);
    text-decoration: none;
  }

  .header__list-item:hover {
    background-color: transparent;
  }
  a.active {
    background-image: url("../../assets/images/menu-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 92px;
  }
}
