.App {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: NewSosis;
  src: url("./assets/fonts/New\ Sosis.ttf");
}

@font-face {
  font-family: GilroyBold;
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: Gilroy;
  src: url("./assets/fonts/Gilroy-Light.ttf");
}

@font-face {
  font-family: CubaoFreeWide;
  src: url("./assets/fonts/Cubao_Free_Wide.otf");
}

@font-face {
  font-family: QuantaGtProBold;
  src: url("./assets/fonts/QuantaGroteskPro-Bold-BF6459a54be213c.otf");
}

@font-face {
  font-family: QuantaGtProLight;
  src: url("./assets/fonts/QuantaGroteskPro-Light-BF6459a54cb7dd6.otf");
}

h1,
h2,
h4 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.dialog-container {
  background-color: white;
  padding: 0px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.shade-image {
  position: absolute;
  top: -28px;
  left: -54px;
  width: 100%;
}

.title-container {
  margin: 2rem;
}
.title {
  font-family: NewSosis;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 3px #000000;
  margin-bottom: 0px;
}

.subtitle-wide {
  font-family: CubaoFreeWide;
  font-size: 44px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 3px #000000;
  margin-top: 0px;
}

.web-element {
  display: none;
}

.mobile-element {
  display: block;
}

.email-link {
  text-decoration: none;
  color: black;
}

@media (min-width: 1025px) {
  .web-element {
    display: block;
  }

  .mobile-element {
    display: none;
  }
}
