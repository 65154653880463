.footer__container {
  background-image: url("../../assets/images/footer-rectangle.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 5% 15%;
  text-align: center;
  color: #fff;
  font-family: Gilroy;
}

.footer__image {
  width: 36px;
  height: 40px;
  margin-top: 1rem;
}

.footer__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
}

.footer__list-item {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin: 1rem 2rem;
}

.footer__copyright-text {
  font-size: 12px;
}

@media (min-width: 1025px) {
  .footer__image {
    width: 72px;
    height: 80px;
  }

  .footer__list {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .footer__list-item:not(:last-child)::after {
    content: " |";
    margin-left: 4rem;
  }

  .footer__copyright-text {
    margin-top: 32px;
  }
}