.insights__container {
  background-color: #0edbe3;
  text-align: center;
  padding: 2rem 0rem 0rem;
}

.insights__title__container,
.insights__desc__container {
  position: relative;
}

.insights__desc__container {
  padding: 0rem;
  overflow: hidden;
}

.floating-img-4 {
  height: 72px;
  width: 72px;
  position: absolute;
  left: 1.5rem;
  top: 1rem;
}
.floating-img-5 {
  height: 90px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 35%;
}
.insights__title__text {
  padding: 1rem 1rem 4rem;
}
.insights__title__subtitle {
  font-family: NewSosis;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ff7b42;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ff7b42;
  -webkit-text-stroke: 1px #000000;
}
.insights__title__title {
  font-family: NewSosis;
  font-size: 45px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 6px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 2px #000000;
}
.floating-img-3 {
  height: 62px;
  width: 62px;
  position: absolute;
  right: 1.5rem;
  top: 0;
}
.floating-img-6 {
  height: 32px;
  width: 32px;
  position: absolute;
  right: 4rem;
  bottom: 0;
}

.floating-img-7 {
  height: 74px;
  width: 72px;
  position: absolute;
  left: -8px;
  top: 0;
}
.floating-img-8 {
  height: 74px;
  width: 72px;
  position: absolute;
  right: -1rem;
  top: 0;
}
.floating-img-9 {
  width: 55%;
}

.insights__desc__text {
  color: #ff69e7;
  font-family: GilroyBold;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #ff69e7;
  -webkit-text-stroke: 0.8px #000000;
  top: 30%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.insights__win__image {
  height: 190px;
}
.thunder-orange {
  position: absolute;
  width: 70px;
  height: 70px;
}
.thunder-pink {
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 0;
  left: 14%;
}

.insights__list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.insights__item {
  position: relative;
  margin-bottom: 2rem;
}
.insights__item-text {
  font-family: GilroyBold;
  font-size: 14px;
  top: 54%;

  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 56%;
}
.insight-1 {
  transform: rotate(-4deg);
}
.insight-2 {
  transform: rotate(2deg);
}
.insight-3 {
  transform: rotate(-4deg);
}
.insights__item-btn {
  font-family: GilroyBold;
  font-size: 14px;
  text-decoration: none;

  padding: 1rem 2rem;
  border-radius: 14px;

  color: #000000;
  background: linear-gradient(0deg, #fff, #fff);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;
  width: 160px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  bottom: -0.5rem;
}

.insights__item-img {
  width: 100%;
}

.insights__win__desc {
  position: relative;
  margin-top: 2rem;
}
.insights__win__desc-text {
  font-family: GilroyBold;
  font-size: 14px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 50%;
}

.insights__footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
.insights__footer_img1 {
  height: 70px;
}
.insights__footer_img2 {
  height: 70px;
}
.insights__footer__mob-img-1 {
  display: block;
}
.insights__footer__desk-img-1 {
  display: none;
}

.insights__footer-text {
  font-family: GilroyBold;
  font-size: 14px;
  margin-bottom: 2rem;
}
.insights__footer-btn {
  color: #000000;
  background: linear-gradient(0deg, #ff68e7, #ff68e7);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  font-family: GilroyBold;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;

  padding: 1rem;
  border-radius: 1rem;
  text-decoration: none;
}

@media (min-width: 1025px) {
  .insights__title__subtitle {
    font-size: 50px;
  }

  .insights__desc__container {
    padding: 2rem;
  }

  .floating-img-4 {
    height: 164px;
    width: 164px;
    top: 0rem;
    left: 25%;
  }
  .floating-img-3 {
    height: 96px;
    width: 96px;
    right: 28%;
  }

  .floating-img-6 {
    display: none;
  }

  .floating-img-7 {
    height: 125px;
    width: 129px;
    left: 20%;
  }

  .floating-img-8 {
    width: 102px;
    height: 100px;
    right: 20%;
  }

  .floating-img-5 {
    height: 115%;
  }
  .floating-img-9 {
    display: none;
  }
  .insights__desc__text {
    position: relative;
    left: 0;
    font-size: 26px;
  }

  .thunder-orange {
    right: 35%;
  }
  .thunder-pink {
    position: absolute;
    width: 170px;
    height: 170px;
    top: -18%;
    left: 30%;
    transform: rotate(45deg);
  }

  .insights__list {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
  }
  .insights__item {
    margin: 4rem;
  }
  .insights__item-text {
    font-size: 16px;
    top: 58%;
  }

  .insights__win__image {
    width: 240px;
    height: 240px;
  }
  .insights__win__desc-text {
    font-size: 18px;
  }

  .insights__footer_img1,
  .insights__footer_img2 {
    height: 190px;
  }
  .insights__footer__mob-img-1 {
    display: none;
  }
  .insights__footer__desk-img-1 {
    display: block;
  }

  .insights__footer-text {
    font-family: GilroyBold;
    font-size: 18px;
  }

  .insights__footer-btn {
    padding: 1rem 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .insights__item-text {
        top: 58%;
    }

    .insights__item-img {
        width: 50%;
    }
}