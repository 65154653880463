.news__container {
  text-align: center;
  background-color: greenyellow;
  /* min-height: 100vh; */
}

.news__title-container {
  margin: 0rem;
  padding: 20% 0% 35%;
}
.news__title {
  color: #ff68e7;
  -webkit-text-fill-color: #ff68e7;
  margin: 0px;
}

.news__back-icon {
  font-size: 40px;
  color: #000;
  margin: 1rem;
}

.news__back-icon-wrapper {
  margin-right: auto;
  text-align: start;
  position: absolute;
  z-index: 1;
}

.news__desc-text {
  /* padding: 16px; */
  padding: 5% 0% 50%;
  font-family: QuantaGtProBold;
  font-size: 19px;
  margin: 0px;
}

@media (min-width: 1024px) {
  .shade-image {
    display: none;
  }

  .news__title-container {
    padding: 5% 0% 0%;
  }

  .news__desc-text {
    padding: 5% 0% 20%;
  }
}
