.about__container {
  text-align: center;
  background-color: #7e93ff;
  /* min-height: 100vh; */
}

.about__title-container {
  margin: 0rem;
  padding: 20% 0% 3%;
}
.about__title {
  color: #0edbe3;
  -webkit-text-fill-color: #0edbe3;
  margin: 0px;
}

.about__back-icon {
  font-size: 40px;
  color: #000;
  margin: 1rem;
}

.about__back-icon-wrapper{
  margin-right: auto;
  text-align: start;
  position: absolute;
  z-index: 1;
}

.wrapper {
  padding: 16px;
}

.about__summary-text {
  padding: 16px;
  font-family: QuantaGtProBold;
  font-size: 19px;
  margin: 0px;
}
.about__summary {
  font-family: QuantaGtProBold;
  font-size: 18px;
  margin: 0px;
}

.about__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about__card-1 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #38e577;
  transform: rotate(-2deg);
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 16px;
}
.about__card-2 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #bef229;
  transform: rotate(1deg);
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 16px;
}
.about__card-3 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #0edbe3;
  transform: rotate(-1deg);
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 16px;
}

.about__card-title {
  font-family: NewSosis;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0px #161716;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1px #000000;
  margin-bottom: 16px;
}

@media (min-width: 1025px) {
  .shade-image {
    display: none;
  }
  .wrapper {
    padding: 3rem 1rem;
    display: flex;
    height: 25vh;
    margin: 0% 5% 5%;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .about__title-container {
    padding: 5% 0% 0%;
  }

  .about__card-1, .about__card-2, .about__card-3 {
    flex: 1;
  }

  .about__summary {
    padding: 16px;
  }

  .about__summary-text {
    padding: 16px 38%;
  }

  .about__card {
    height: 172px;
    margin: 0% 2%;
  }

  .about__card-1 {
    transform: rotate(4deg);
  }
  .about__card-2 {
    transform: rotate(-2deg);
  }
  .about__card-3 {
    transform: rotate(2deg);
  }

  .about__card-title {
    margin-bottom: 0px;
  }
}
