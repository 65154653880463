.careers__container {
  text-align: center;
  background-color: #ff68e7;
  min-height: 100vh;
}

.careers__title-container {
  margin: 0rem;
  padding: 20% 0% 3%;
}
.careers__title {
  color: #38e577;
  -webkit-text-fill-color: #38e577;
  margin: 0px;
}

.careers__back-icon {
  font-size: 40px;
  color: #000;
  margin: 1rem;
}

.careers__back-icon-wrapper {
  margin-right: auto;
  text-align: start;
  position: absolute;
  z-index: 1;
}

.wrapper {
  padding: 0px;
}

.careers__summary-text {
  padding: 16px;
  font-family: QuantaGtProBold;
  font-size: 19px;
  margin: 0px;
}
.careers__summary {
  font-family: QuantaGtProBold;
  font-size: 18px;
  margin: 0px;
}

.careers__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.careers__card-1 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #38e577;
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 16px;
}
.careers__card-2 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #b398ff;
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 16px;
}

.careers__card-3__container {
  padding: 4rem 0rem 3rem;
}
.careers__card-3 {
  border: 1px solid #000;
  border-radius: 8px;
  background: #ffdf0d;
  box-shadow: 1px 3px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 0px 1rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.careers__card-3-text {
  margin-left: 39%;
}

.careers__reachout-text {
  font-family: QuantaGtProLight;
  font-size: 16px;
  margin-bottom: 0px;
}

.boy-image {
  width: 46%;
  z-index: 1;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.careers__card-title {
  font-family: NewSosis;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0px #161716;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1px #000000;
  margin-bottom: 16px;
}

.careers__card-subtitle {
  font-family: QuantaGtProBold;
}

.bg_color{
  background-color: #ff68e7;
}

.live_cont{
  background-image: url("https://www.vyld.io/static/media/about-bg-drop.baa8b307c01eaa66db1d.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  z-index: 999;
  /* background-color: yellow; */
}

.extra_cont{
  margin-top: 5vh;
  height: 10vh;
  z-index: 999;
  background: #000;
}

.img_cont{
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 8vh;
  flex-direction: column;
  align-items: center;
}
.live_img1{
  height: 45vh;
  width: 60vw;
  transform: rotate(-10deg);
  border:10px solid white;
  cursor: pointer;
}

.live_img2{
  height: 45vh;
  width: 60vw;
  transform: rotate(6deg);
  margin-left: 3vw;
  border:10px solid white;
  cursor: pointer;
}
.bg_yellow{
  background-color: yellow;
}
.bg_black{
  background-color: black;
}


@media (min-width: 1025px) {
  .shade-image {
    display: none;
  }

  .wrapper {
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    margin: 0% 5%;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .careers__title-container {
    padding: 5% 0% 0%;
  }

  .careers__card-1,
  .careers__card-2,
  .careers__card-3 {
    flex: 1;
  }

  .careers__summary {
    padding: 8px 0px 0px;
  }

  .careers__summary-text {
    padding: 16px 36%;
  }

  .careers__card {
    width: 320px;
    height: 154px;
    margin: 0% 2%;
  }

  .careers__card-1 {
    transform: rotate(-2deg);
  }
  .careers__card-2 {
    transform: rotate(2deg);
  }
  .careers__card-3 {
    transform: rotate(0deg);
    height: auto;
    position: absolute;
    left: 40%;
    margin: 35px 0 0 0px;
    width: 370px;
  }

  .careers__card-3__container {
    text-align: center;
    position: relative;
    height: 180px;
    padding: 0rem 0rem 3rem;
  }

  .careers__card-title {
    margin-bottom: 0px;
  }

  .boy-image {
    width: 50%;
  }

  .img_cont{
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin-top: 8vh;
  }

  .live_img1{
    height: 45vh;
    width: 20vw;
    transform: rotate(-10deg);
    border:10px solid white;
  }
  
  .live_img2{
    height: 45vh;
    width: 20vw;
    transform: rotate(6deg);
    margin-left: 3vw;
    border:10px solid white;
  }
  
}
