.otp-dialog__container {
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.otp-dialog__title {
  font-family: GilroyBold;
  margin-top: 32px;
}
.otp-dialog__subtitle {
  font-family: Gilroy;
  margin-top: 0px;
  margin-bottom: 32px;
}

.otp-dialog__input-group {
  display: flex;
  justify-content: center;
}

.otp-dialog__input-group input {
  width: 40px;
  height: 50px;
  margin: 0 5px;
  text-align: center;
  font-size: 20px;
  color: black;
  background-color: lightgray;
  text-shadow: none;
  border-radius: 8px;
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 0px #000000;
  font-family: GilroyBold;
}

.otp-dialog__input-group input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-dialog__input-group input[type='number'] {
  padding: 5px; 
  border: 1px solid #ccc;
}

.otp-dialog__timer {
  margin-top: 8px;
}

.otp-dialog__timer button {
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.otp-dialog__timer button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button {
  font-family: Gilroy;
  font-weight: bold;
  font-size: 16px;
}

.register-button__disabled {
  color: gray;
  padding: 16px 94px;
  border-radius: 8px;
  background: linear-gradient(0deg, #eca5e1, #eca5e1);
  border: 2px solid gray;
  box-shadow: 2px 3px 0px 0px gray;
  text-shadow: 0px 0px gray;
  font-size: 16px;
  margin-top: 28px;
  -webkit-text-fill-color: gray;
  -webkit-text-stroke: 0px #000000;
}

.register-button__enabled {
  color: #000000;
  padding: 16px 94px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ff68e7, #ff68e7);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  font-size: 16px;
  margin-top: 28px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;
  cursor: pointer;
}

