@font-face {
    font-family: 'GilroyBold';
    src: url("../../assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: 'Gilroy';
    src: url("../../assets/fonts/Gilroy-Light.ttf");
}

.header {
    font-family: 'GilroyBold';
    color: #333;
    margin-bottom: 20px;
}

.content, p {
    font-family: 'Gilroy';
    color: #666;
}

.tncMain {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

ol {
    font-family: 'Gilroy';
    font-size: 16px; /* Ensuring the list font size matches content */
    padding-left: 20px; /* Proper indentation for readability */
}

ol li {
    margin-bottom: 10px; /* Space between list items for clarity */
}