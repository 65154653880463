@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes floatAnimationUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Safari */
@-webkit-keyframes fade-in-snaps {
  from {
    -webkit-transform: scale(0.8);
    opacity: 0.7;
  }
  to {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in-snaps {
  from {
    transform: scale(0.8);
    opacity: 0.7;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

iframe {
  border: none;
}

.home__container {
  position: relative;
  width: 100%;
}
.home__video-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.background-video-mob {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

.background-video-web {
  display: none;
}

.home__video-text-wrapper {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 65%;
}

.home__video-img {
  width: 100%;
}

.home__video-text {
  font-family: QuantaGtProBold;
  font-size: 17px;
  margin: 5% 10%;
}

.home__early-access-btn {
  transform: rotate(-4deg);
}

.home__about-container {
  background-color: #0edbe3;
  background-image: url("../../assets/images/about-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  height: 518px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content-container {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #f0f0f0;
  overflow: hidden;
}

.content-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #f0f0f0;
}

.home__about-welcome-text {
  font-family: QuantaGtProBold;
  font-size: 20px;
  margin: 0px;
}

.home__about-subtitle {
  font-family: CubaoFreeWide;
  font-size: 44px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 2px #000000;
  margin-top: 0px;
}

.home__about-desc-text {
  font-family: QuantaGtProBold;
  margin-bottom: 0px;
  display: flex;
  align-items: center;

  font-size: 32px;
  color: #4856F7;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #4856F7;
  -webkit-text-stroke: 1px #000000;
}
.home__about-desc-text-2 {
  font-family: QuantaGtProLight;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin: 0rem;
}

.home__about-feat-title {
  font-family: GilroyBold;
  font-size: 20px;
}

.home__about-feat-subtitle {
  font-family: Gilroy;
  font-weight: 800;
  font-size: 17px;
  margin: 1rem 10%;
}

.home__about-genz {
  font-family: NewSosis;
  font-size: 20px;
  color: #ff68e7;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #ff68e7;
  -webkit-text-stroke: 1px #000000;
  transform: rotate(-4deg);
  display: inline-block;
  margin-left: 4px;
}

.home__about-heart-icon {
  display: inline-block;
  transform: rotate(4deg);
  width: 32px;
  height: 32px;
}

.home__about-button {
  font-family: GilroyBold;
  font-size: 14px;
  text-decoration: none;

  padding: 1rem 2rem;
  border-radius: 14px;

  color: #000000;
  background: linear-gradient(0deg, #fff, #fff);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;

  position: absolute;
  z-index: 1;
  bottom: -16px;
}

.home__about-feat-list {
  padding: 0rem 2rem;
}

.feature {
  width: 100%;
}

.home__app-image-container {
  background-color: #e2ffee;
  background-image: url("../../assets/images/background-aliceblue.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
}
.home__app-image {
  width: 100%;
  height: auto;
  padding: 2% 2px;
  -webkit-animation: fade-in-snaps 1s ease forwards; /* Safari */
  animation: fade-in-snaps linear;
  animation-timeline: view();
}

.home__signup-container {
  padding: 10% 16px 5%;
  background-color: #e2ffee;
}

.home__feed {
  text-align: center;
  position: relative;
  background-image: url("../../assets/images/background-aliceblue.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
  background-color: #161716;
}

.home__tribe-bg {
  background-image: linear-gradient(#e2ffee, #0edbe3);
}

.home__tribe {
  background-image: url("../../assets/images/tribe-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 465px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon {
  margin-right: 1rem;
}

.floral-string {
  height: 8%;
  width: 100%;
}

.home__tribe__icons-wrapper {
  overflow-y: hidden;
  width: 100%;
}

.home__tribe__icons-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 16rem;
}

.home__tribe__icons-list::-webkit-scrollbar {
  display: none;
}

.videos-icon {
  width: 112px;
  height: 122px;
}
.events-icon {
  width: 123px;
  height: 125px;
}
.blogs-icon {
  width: 101px;
  height: 111px;
}
.forums-icon {
  width: 110px;
  height: 120px;
}
.contests-icon {
  width: 95px;
  height: 113px;
}

.home__tribe-title {
  font-family: NewSosis;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ff68e7;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ff68e7;
  -webkit-text-stroke: 2px #000000;
  margin-bottom: 0px;
}

.home__feed-title-container,
.home__tribe-title-container {
  margin: 2rem;
}
.home__feed-title {
  font-family: NewSosis;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 2px #000000;
  margin-bottom: 0px;
}

.home__feed-subtitle {
  font-family: CubaoFreeWide;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 2px #000000;
  margin-top: 0px;
}

.home__feed-bgimage1,
.home__feed-bgimage2 {
  width: 104%;
}

.home__feed-bgimage1 {
  margin: 40% 0px 0px;
}

.home__feed-image {
  width: 100%;
  position: absolute;
  width: 90%;
  height: 58%;
  transform: rotate(4deg);
  top: 29%;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.home__socials-container {
  background-color: #161716;
  padding: 2rem;
}

.home__socials-icons-list {
  background-image: url("../../assets/images//socials-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  text-align: center;

  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.home__socials-icon {
  width: 54px;
  height: 54px;
}

.home__socials-set1 {
  display: flex;
  justify-content: space-between;
  animation: floatAnimationUpDown 3s ease-in-out infinite;
}

.yt-icon {
  margin-left: 3rem;
}

.home__socials-set2 {
  display: flex;
  justify-content: space-between;
  animation: floatAnimation 3s ease-in-out infinite;
}
.home__socials-set2 img {
  width: 68px;
  height: 68px;
}
.li-icon {
  margin-top: 1rem;
}
.fb-icon {
  margin-top: 3rem;
}
.insta-icon {
  margin-top: 2rem;
}

.home__socials-title {
  font-family: CubaoFreeWide;
  font-size: 28px;
  color: #ff68e7;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #ff68e7;
  -webkit-text-stroke: 1px #000000;
  padding-top: 1rem;
}

.home__footer-container {
  background-color: #161716;
}

@media (min-width: 1025px) {
  .home__video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .background-video-web {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -1;
  }

  .background-video-mob {
    display: none;
  }

  .home__video-text-wrapper {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    top: 75%;
  }

  .home__video-img {
    width: 76%;
  }
  .home__video-text {
    font-size: 22px;
    margin: 2% 36% 1%;
  }

  .home__about-container {
    height: 100%;
    padding: 4rem;

    background-image: url("../../assets/images/about-bg-drop.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home__about-welcome-text {
    font-size: 22px;
  }

  .home__about-desc-text {
    font-size: 44px;
    margin-top: 1rem;
  }

  .home__about-desc-text-2 {
    font-size: 24px;
  }

  .home__about-feat-title,
  .home__about-feat-subtitle {
    font-size: 20px;
  }

  .home__about-feat-title {
    margin: 2rem;
  }

  .home__about-app-name {
    font-size: 56px;
  }

  .home__about-genz {
    font-size: 32px;
  }
  .home__about-button {
    font-size: 18px;
  }
  .home__about-feat-list {
    padding: 0rem 2rem;
  }
  .feature {
    width: 40%;
  }
  .home__app-image {
    width: 80%;
    height: auto;
    margin: 0px 10%;
  }

  .home__feed {
    padding: 2% 35%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home__feed-bgimage1,
  .home__feed-bgimage2 {
    width: 104%;
  }
  .home__feed-image {
    height: 50%;
    width: 25%;
    top: 28%;
    position: absolute;
  }
  .home__feed-bgimage1 {
    margin: 10% 0px;
  }

  .home__tribe {
    width: 100%;
    left: 0px;
    position: relative;
    background-repeat: repeat;
    background-size: contain;
  }

  .home__tribe__icons-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 2% 0%;
    padding-left: 0px;
  }

  .icon {
    margin: 0% 2%;
  }

  .home__socials-container {
    padding: 7rem 38%;
  }
  .home__socials-icons-list {
    width: 395px;
    height: 388px;
  }

  .li-icon {
    margin-top: 1rem;
  }
  .fb-icon {
    margin-top: 3rem;
    margin-right: 2rem;
  }
  .insta-icon {
    margin-top: 2rem;
  }

  .home__socials-title {
    padding-top: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home__video-container {
    height: 200vh;
  }

  .home__about-container {
    padding: 3rem;
  }

  .home__tribe__icons-list {
    padding-left: 0px;
  }

  .home__feed-bgimage1,
  .home__feed-bgimage2 {
    width: 100%;
  }
  .home__feed-image {
    top: 25%;
  }
}
