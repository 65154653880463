input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.img_PRI {
  width: 95vw;
  margin-left: 2vw;
}

.container_PRI {
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: white;
  width: 100vw;
  height: 80vh;
  opacity: 1;
}

.subContainer_PRI {
  margin: 0vh 0vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  color: white;
}
.input_Rect_PRI {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 85vw;
  /* height: 23.5vh; */
  height: 100%;
  border-radius: 8px 8px 8px 8px;
  margin-top: 4vh;
  background: var(--Accent-Accent--02-Accent-02-neon, #ff5af9);
  border: 2px solid #350ea3;
  box-shadow: 1px 4px 0px 0px #350ea3;
}
.input_react_head {
  margin-top: 2.5vh;
  font-family: Gilroy;
  font-size: 2.3vh;
  font-weight: 700;
  text-align: center;
}

.inputContainer1_PRI {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 34px; */
  height: 4.4vh;
  width: 90%;
  margin-top: 2.5vh;
  border-radius: 6px 6px 6px 6px;
  border: 1px 0px 0px 0px;
  background: var(--Neutral-Light-alpha-30, #ffffff4d);
  border: 1px solid #ffffff;
}

.radioContainer_PRI {
  display: flex;
  margin-bottom: 3vh;
  align-items: center;
}

.radioOption_PRI {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 6px;
  margin-left: 2vw;
  margin: 0px 0px 0px 5px;
}

.submitButton_PRI {
  width: 200px;
  height: 46px;
  border-radius: 2.5rem;
  background: #ffffff;
  border: 1px solid var(--Dark-Dark--100, #0e1928);
  box-shadow: 1px 0px 0px 0px #0e1928;
  margin: 2vh;
  border-width: 1px 2px 3px 1px;
  z-index: 1;
}
.submitButton_PRI span {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: var(--Neutral-Dark-100, #161716);
}
.fdbk_PRI {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: var(--Neutral-Dark-100, #161716);
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 1vw; */
}
.dirc_PRI {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: center;
  width: 75vw;
  margin-top: 2vh;
  width: 81%;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating-img_PRI {
  animation: rotate 2s linear infinite;
  height: 2.5vh;
}

.submitButtonaf_PRI {
  width: 200px;
  height: 46px;
  border-radius: 2.5rem;
  background: #ffffff;
  border: 1px solid var(--Dark-Dark--100, #0e1928);
  box-shadow: 1px 0px 0px 0px #0e1928;
  margin: 2vh;
  border-width: 1px 2px 3px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.submitButtonaf_PRI div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.submitButtonaf_PRI div span:nth-child(1) {
  text-align: center;
  color: var(--Neutral-Dark-100, #161716);
  font-family: Gilroy;
  font-size: 9px;
  font-weight: 600;
  line-height: 10.74px;
  letter-spacing: -0.30000001192092896px;
}
.submitButtonaf_PRI div span:nth-child(2) {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: var(--Neutral-Dark-100, #161716);
}

.numberInput_PRI {
  background: transparent;
  outline: none;
  font-size: 3.1vh;
  color: white;
  /* padding: 0px; */
  letter-spacing: 5px;
  letter-spacing: 1.2vw;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #fcff40;
  -webkit-text-stroke: 1px #000000;
  caret-color: black;
  border: none;
  margin: 0;
  text-align: left;
  padding-left: 2vw;
  /* font-family: 'Gilroy'; */
}

.numberInput_PRI::placeholder {
  /* font-family: Gilroy; */
  font-size: 3.1vh;
  font-weight: 400;
  letter-spacing: 5px;
  letter-spacing: 1.2vw;
  /* text-border: 1.5px solid #FFFFFF; */
  /* color: #FFFFFF; */
  /* text-shadow: 0px 1.5px 0px 0px #FFFFFF; */
}

.numberInputaf_PRI {
  font-size: 3.1vh;
  color: white;
  letter-spacing: 5px;
  letter-spacing: 1.2vw;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #fcff40;
  -webkit-text-stroke: 1px #000000;
  caret-color: black;
  margin: 0;
  background: var(--Neutral-Light-alpha-30, #ffffff4d);
  border: 0.75px solid #ffffff;
  height: 6vh;
  border-radius: 6px;
  box-sizing: border-box;
  padding-left: 1.5%;
  z-index: 2;
}

.numberInputaf_PRI::placeholder {
  font-size: 3.1vh;
  font-weight: 400;
  letter-spacing: 5px;
  letter-spacing: 1.2vw;
}
.btn_store_PRI {
  margin: 2vh;
  width: 50vw;
  z-index: 5;
}

@media (max-width: 768px) {
  .container_PRI {
    margin-top: 6vh;
  }

  /* .submitButton_PRI {
    width: 220px;
  } */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4vh;
}

.outer-circle {
  position: relative;
  width: 3vh; 
  height: 3vh; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(black 0%, rgba(22, 23, 22, 0.4) 0%);
}

.inner-circle {
  width: 1.4vh; 
  height: 1.4vh; 
  background-color: #ff5af9; 
  border-radius: 50%;
  z-index: 1; 
}

.resendOtp_PRI {
  font-family: Gilroy;
  font-size: 1.8vh;
  font-weight: bold;
  line-height: 14.4px;
  color: rgba(22, 23, 22, 0.6); 
  z-index: 1;
  width:12vh;
}

@media (min-width:700px) {
  .img_PRI{
    width: 600px;
  }
  .input_Rect_PRI{
    width: 620px;
  }
  .btn_store_PRI{
    width: 10rem;
  }
}
