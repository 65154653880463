.success__container {
  background-color: #38e577;
  text-align: center;
}

.success__bg {
  background-image: url("../../assets/images/crushed-paper.png");
}

.snow-img {
  height: 36px;
  width: 100%;
}

.success__title__container,
.success__desc__container {
  position: relative;
}

.success__desc__container {
  padding: 0rem;
}

.success__desc__title {
  font-family: NewSosis;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ff7b42;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ff7b42;
  -webkit-text-stroke: 1px #000000;
  top: 16%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.sfloating-img-4 {
  height: 72px;
  width: 72px;
  position: absolute;
  left: 1.5rem;
  bottom: -7%;
}
.sfloating-img-5 {
  height: 90px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 35%;
}
.success__title__text {
  padding: 1rem 1rem 4rem;
}
.success__title__subtitle {
  font-family: GilroyBold;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 0px #161716;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1px #000000;
  transform: rotate(3deg);
  margin-bottom: 1rem;
}
.success__title__title {
  font-family: NewSosis;
  font-size: 45px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 6px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 2px #000000;
}
.sfloating-img-3 {
  height: 62px;
  width: 62px;
  position: absolute;
  right: 1.5rem;
  bottom: 0;
}
.sfloating-img-6 {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 8rem;
  top: 40%;
}
.sfloating-img-10 {
  display: none;
}
.sfloating-img-7 {
  height: 74px;
  width: 72px;
  position: absolute;
  left: -8px;
  bottom: -80%;
}
.sfloating-img-8 {
  height: 74px;
  width: 72px;
  position: absolute;
  right: 0rem;
  bottom: -80%;
}
.ssfloating-img-9 {
  width: 55%;
  text-align: center;
}

.success__desc__text {
  color: #fff59b;
  font-family: NewSosis;
  font-size: 15px;
  text-shadow: 0px 1px #161716;
  -webkit-text-fill-color: #fff59b;
  -webkit-text-stroke: 1px #000000;
  top: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  transform: rotate(-0.57deg);
}

.success__win__image {
  height: 190px;
}
.thunder-orange {
  position: absolute;
  width: 70px;
  height: 70px;
}
.thunder-pink {
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 0;
  left: 14%;
}

.success__list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.success__item {
  position: relative;
  margin-bottom: 2rem;
}
.success__item-text {
  font-family: GilroyBold;
  font-size: 14px;
  top: 54%;

  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 56%;
}
.insight-1 {
  transform: rotate(-4deg);
}
.insight-2 {
  transform: rotate(2deg);
}
.insight-3 {
  transform: rotate(-4deg);
}
.success__item-btn {
  font-family: GilroyBold;
  font-size: 14px;
  text-decoration: none;

  padding: 1rem 2rem;
  border-radius: 14px;

  color: #000000;
  background: linear-gradient(0deg, #fff, #fff);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;
  width: 160px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  bottom: -0.5rem;
}

.success__item-img {
  width: 100%;
}

.success__features__container {
  position: relative;
  margin-top: 20%;
}
.success__features_title {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  font-family: NewSosis;
  font-size: 15px;
  text-shadow: 0px 1px #161716;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 0.5px #000000;
}
.success__title__desc {
  font-family: GilroyBold;
  font-size: 14px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 100%;
  padding: 0rem 1rem;
  transform: rotate(2deg);
}

.success__features__list__container {
  position: relative;
}
.sf-image {
  width: calc(46% - 10px);
  height: 100%;
}
.success__features__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px 4rem;
  position: absolute;
  top: 0;
}

.sfpink-bg {
  width: 100%;
  height: 100%;
}

.success__bonus__container {
  margin: 16% auto;
}
.success__bonus__list__container {
  position: relative;
}
.success__bonus__list-1 {
  display: flex;
  justify-content: space-around;
  margin-bottom: -12%;
}
.success__bonus__list-2 {
  display: flex;
  justify-content: space-around;
  margin: -7% 0px;
}
.sb-image {
  width: 100px;
  height: 100%;
}
.sb-image-1 {
}
.sb-image-2 {
}
.sb-image-3 {
}
.sb-image-4 {
}
.sb-image-5 {
}

.sflighting-icon {
  display: none;
}

.sfeye-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -40%;
  right: 5%;
}

.success__footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
.success__footer_img1 {
  height: 70px;
}
.success__footer_img2 {
  height: 70px;
}
.success__footer__mob-img-1 {
  display: block;
}
.success__footer__desk-img-1 {
  display: none;
}

.success__footer-text {
  font-family: GilroyBold;
  font-size: 14px;
  margin-bottom: 2rem;
}
.success__footer-btn {
  color: #000000;
  background: linear-gradient(0deg, #ff68e7, #ff68e7);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  font-family: GilroyBold;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;

  padding: 1rem;
  border-radius: 1rem;
  text-decoration: none;
}

@media (min-width: 1025px) {
  .snow-img {
    height: 164px;
  }

  .success__desc__title {
    font-size: 35px;
  }
  .success__title__subtitle {
    font-size: 56px;
    text-shadow: 0px 4px 0px #161716;
  }

  .success__desc__container {
    padding: 0rem 2rem 2rem;
  }

  .sfloating-img-4 {
    height: 164px;
    width: 164px;
    top: 0rem;
    left: 25%;
  }
  .sfloating-img-3 {
    height: 96px;
    width: 96px;
    right: 28%;
  }

  .sfloating-img-6 {
    height: 55px;
    width: 55px;
    left: 30%;
  }

  .sfloating-img-10 {
    display: block;
    top: 0;
    height: 69px;
    width: 49px;
    position: absolute;
    right: 20%;
    z-index: 1;
  }

  .sfloating-img-7 {
    height: 125px;
    width: 129px;
    left: 20%;
    bottom: -35%;
  }

  .sfloating-img-8 {
    width: 102px;
    height: 100px;
    right: 20%;
    bottom: -30%;
  }

  .sfloating-img-5 {
    height: 134%;
  }

  .ssfloating-img-9 {
    width: 20%;
  }

  .success__features_title {
    font-size: 24px;
    margin: 1rem;
  }

  .success__desc__text {
    font-size: 20px;
  }

  .sfpink-bg {
    width: 45%;
  }

  .sf-image {
    width: calc(36% - 10px);
    height: 100%;
  }

  .success__features__list__container {
    overflow: hidden;
  }
  .success__features__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px 34%;
    position: absolute;
    top: 0;
  }

  .success__features__container {
    margin-top: 12%;
  }

  .sflighting-icon {
    display: block;
    position: absolute;
    width: 170px;
    height: 170px;
    left: -2%;
    bottom: 0px;
  }
  .sfeye-icon {
    position: absolute;
    width: 170px;
    height: 170px;
    bottom: 0px;
    right: 5%;
  }

  .success__bonus__container {
    margin: 10% auto;
  }

  .sb-image {
    width: 160px;
    margin-bottom: 16px;
    height: 100%;
  }

  .success__bonus__list-1 {
    margin-bottom: -7%;
  }
  .success__bonus__list-2 {
    margin: -8% 25% 0px;
  }

  .success__list {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
  }
  .success__item {
    margin: 4rem;
  }
  .success__item-text {
    font-size: 16px;
    top: 58%;
  }

  .success__win__image {
    width: 240px;
    height: 240px;
  }
  .success__title__desc {
    font-size: 20px;
    padding: 1rem 30%;
    top: 145%;
  }

  .success__footer_img1,
  .success__footer_img2 {
    height: 190px;
  }
  .success__footer__mob-img-1 {
    display: none;
  }
  .success__footer__desk-img-1 {
    display: block;
  }

  .success__footer-text {
    font-family: GilroyBold;
    font-size: 18px;
  }

  .success__footer-btn {
    padding: 1rem 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .success__item-text {
    top: 58%;
  }

  .success__item-img {
    width: 50%;
  }
}
