.jas__container {
  background-color: #0edbe3;
  text-align: center;
  min-height: 100vh;
}

.jas__bg {
  background-image: url("../../assets/images/crushed-paper.png");
}

.snow-img {
  height: 36px;
  width: 100%;
}

.jas__title__container,
.jas__desc__container {
  position: relative;
}

.jas__desc__container {
  padding: 0rem;
}

.jas__desc__title {
  font-family: NewSosis;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ff7b42;
  text-shadow: 2px 3px 0px #161716;
  -webkit-text-fill-color: #ff7b42;
  -webkit-text-stroke: 1px #000000;
  top: 16%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.jasfloating-img-4 {
  height: 102px;
  width: 102px;
  position: absolute;
  bottom: -98%;
  z-index: 1;
  left: 7%;
}
.jafloating-img-5 {
  height: 100px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 35%;
}
.jas__title__text {
  padding: 1rem 1rem 4rem;
  margin-top: 12%;
}
.jas__title__subtitle {
  font-family: GilroyBold;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 0px #161716;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1px #000000;
  transform: rotate(3deg);
  margin-bottom: 1rem;
}
.jas__title__title {
  font-family: NewSosis;
  font-size: 45px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffdf0d;
  text-shadow: 2px 6px 0px #161716;
  -webkit-text-fill-color: #ffdf0d;
  -webkit-text-stroke: 2px #000000;
}
.jasfloating-img-3 {
  height: 62px;
  width: 62px;
  position: absolute;
  right: 1.5rem;
  top: 2rem;
}
.jasfloating-img-6 {
  z-index: 1;
  height: 65px;
  width: 37px;
  position: absolute;
  right: 1rem;
  top: 192%;
}
.jasfloating-img-10 {
  display: none;
}
.jasfloating-img-7 {
  height: 74px;
  width: 72px;
  position: absolute;
  left: 50%;
  top: -16px;
  z-index: 1;
}
.jasfloating-img-8-desk {
  display: none;
}
.jasfloating-img-8 {
  display: block;
  height: 74px;
  width: 72px;
  position: absolute;
  top: 2rem;
  left: 3rem;
  z-index: 1;
}
.sjasfloating-img-9 {
  width: 55%;
  text-align: center;
}

.jasfloating-img-11 {
  width: 80%;
  position: absolute;
  left: -25px;
  top: 6%;
  height: 12%;
}

.jas__desc__text {
  color: #fff59b;
  font-family: NewSosis;
  font-size: 15px;
  text-shadow: 0px 1px #161716;
  -webkit-text-fill-color: #fff59b;
  -webkit-text-stroke: 1px #000000;
  top: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  transform: rotate(-0.57deg);
}

.joinas__title__desc {
  font-family: GilroyBold;
  font-size: 16px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  top: 120%;
  padding: 0rem 1rem;
}

.ja-signup-container {
  min-height: 58vh;
  background-image: url("../../assets/images/join-as-yellow-bg.png");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  flex-direction: column;
  justify-content: center;
  margin-top: 20%;
  padding: 7rem 0px 1rem;
}

.instaid-input,
.phone-input,
.email-input,
.name-input {
  outline: none;
  border: #161716;
  text-align: center;
  font-family: NewSosis;
  font-size: 20px;
  color: #0edbe3;
  background: transparent;
  text-shadow: 2px 3px 0px #161716;
  margin: 1rem 0rem 0.5rem;
  -webkit-text-fill-color: #0edbe3;
  -webkit-text-stroke: 1px #000000;
  caret-color: black;
}
.jaSignup-form input[type="submit"] {
  font-family: GilroyBold;
  padding: 16px 94px;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 28px;
}
.jaSignup-form input::placeholder {
  color: transparent;
  outline: #161716;
  text-shadow: 0px 0px 0px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.5px #16171699;
  text-align: center;
}

.jaSignup-form__name-wrapper,
.jaSignup-form__phone-wrapper,
.jaSignup-form__email-wrapper,
.jaSignup-form__instaId-wrapper {
  position: relative;
}
.ja-required {
  font-size: 25px;
  position: absolute;
  text-align: center;
  -webkit-text-fill-color: red;
  -webkit-text-stroke: 0.2px #000000;
}

.jaSignup-form__name-wrapper .ja-required {
  right: 40%;
}
.jaSignup-form__email-wrapper .ja-required {
  right: 36%;
}
.jaSignup-form__phone-wrapper .ja-required {
  right: 38%;
}

.jas-otp-button__disabled {
  color: gray;
  background: rgba(240, 240, 240, 0.5);
  border: 2px solid gray;
  box-shadow: 2px 3px 0px 0px gray;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: gray;
  -webkit-text-stroke: gray;
  font-weight: bold;
}

.jas-otp-button__enabled {
  color: #000000;
  background: linear-gradient(0deg, #ff68e7, #ff68e7);
  border: 2px solid #161716;
  box-shadow: 2px 3px 0px 0px #161716;
  text-shadow: 0px 0px #161716;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke: 0px #000000;
  cursor: pointer;
}

.jas__footer-text {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 2rem;
  padding: 1rem;
}

@media (min-width: 1025px) {
  .snow-img {
    height: 164px;
  }

  .jas__title__text {
    margin-top: 0px;
  }
  .jas__desc__title {
    font-size: 35px;
  }
  .jas__title__subtitle {
    font-size: 56px;
    text-shadow: 0px 4px 0px #161716;
  }

  .jas__desc__container {
    padding: 0rem 2rem 2rem;
  }

  .jasfloating-img-4 {
    height: 164px;
    width: 164px;
    bottom: -188%;
    z-index: 1;
    left: 7%;
  }
  .jasfloating-img-3 {
    height: 96px;
    width: 96px;
    right: 28%;
    top: 116%;
    z-index: 1;
  }

  .jasfloating-img-6 {
    height: 91px;
    width: 52px;
    left: 15%;
    top: 145%;
  }

  .jasfloating-img-10 {
    display: block;
    top: 80%;
    height: 58px;
    width: 48px;
    position: absolute;
    right: 26%;
    z-index: 1;
  }

  .jasfloating-img-7 {
    height: 125px;
    width: 129px;
    left: 27%;
    bottom: -120px;
    z-index: 1;
  }

  .jasfloating-img-8 {
    display: none;
  }
  .jasfloating-img-8-desk {
    display: block;
    position: absolute;
    width: 102px;
    height: 100px;
    right: 30%;
    bottom: -120px;
    z-index: 1;
  }

  .jafloating-img-5 {
    height: 100%;
  }

  .sjasfloating-img-9 {
    width: 20%;
  }

  .jasfloating-img-11 {
    width: 60%;
    position: absolute;
    left: 0;
    top: 20%;
    height: 36%;
  }

  .jas__features_title {
    font-size: 24px;
    margin: 1rem;
  }

  .jas__desc__text {
    font-size: 20px;
  }

  .sfpink-bg {
    width: 45%;
  }

  .sf-image {
    width: calc(36% - 10px);
    height: 100%;
  }

  .ja-signup-container {
    background-image: url("../../assets/images/join-as-yellow-bg.png");
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 6%;
    min-height: 55vh;
    padding: 2rem;
  }

  .joinas__title__desc {
    font-size: 20px;
    padding: 1rem 30%;
    top: 145%;
  }

  .jaSignup-form__name-wrapper .ja-required {
    right: 48%;
  }
  .jaSignup-form__email-wrapper .ja-required {
    right: 47%;
  }
  .jaSignup-form__phone-wrapper .ja-required {
    right: 48%;
  }

  .jas__footer-text {
    font-family: Gilroy;
    font-weight: 600;
    padding: 1rem 34%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .jaSignup-form__name-wrapper .ja-required {
    right: 44%;
  }
  .jaSignup-form__email-wrapper .ja-required {
    right: 43%;
  }
  .jaSignup-form__phone-wrapper .ja-required {
    right: 44%;
  }
}
