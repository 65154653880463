.tribe__container {
  text-align: center;
  background-color: greenyellow;
  /* min-height: 100vh; */
}

.tribe__title-container {
  margin: 0rem;
  padding: 20% 0% 35%;
}
.tribe__title {
  color: #ff68e7;
  -webkit-text-fill-color: #ff68e7;
  margin: 0px;
}

.tribe__coming-text {
  font-size: 27px;
  color: #fff;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke: 1px #000000;
}

.tribe__coming-soon-text {
  padding: 5% 0% 50%;
}

.tribe__soon-text {
  font-size: 38px;
  color: #ff68e7;
  -webkit-text-fill-color: #ff68e7;
  -webkit-text-stroke: 1px #000000;
}

.tribe__back-icon {
  font-size: 40px;
  color: #000;
  margin: 1rem;
}

.tribe__back-icon-wrapper {
  margin-right: auto;
  text-align: start;
  position: absolute;
  z-index: 1;
}

@media (min-width: 1024px) {
  .shade-image {
    display: none;
  }

  .tribe__title-container {
    padding: 5% 0% 0%;
  }

  .tribe__coming-soon-text {
    padding: 5% 0% 20%;
  }

  .tribe__coming-text {
    font-size: 32px;
  }
}
