@font-face {
    font-family: 'GilroyBold';
    src: url("../../assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: 'Gilroy';
    src: url("../../assets/fonts/Gilroy-Light.ttf");
}

.header {
    font-family: 'GilroyBold';
    color: #333;
    margin-bottom: 20px;
}

.content, p {
    font-family: 'Gilroy';
    color: #666;
}

.privacyPolicyMain {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}